import { FC } from 'react';
import { Link } from 'react-justanother-router';

import { ListItem } from '@/shared';
import { RouterName } from '@app/router';
import { Spacer, Typography } from '@house-booking/ui-kit';
import { BookingResponse } from '@shared/api';
import { formatAmount, isCurrentDateInRange } from '@shared/libs';

import { HouseHistoryStatus } from '../HouseHistoryStatus/HouseHistoryStatus';

import 'swiper/css/pagination';

import 'swiper/css';
import styles from './styles.module.scss';

interface HouseHistoryCardProps {
  booking: BookingResponse;
}
export const HouseHistoryCard: FC<HouseHistoryCardProps> = ({
  booking,
}): JSX.Element => (
  <Link
    to={RouterName.HistoryDetail}
    params={{ id: booking.id }}
    className={styles.card}>
    <HouseHistoryStatus
      className={styles.card__label}
      status={booking.status!}
    />
    <Typography
      color={
        isCurrentDateInRange(booking.start_date!, booking.end_date!)
          ? 'primary-1'
          : 'on-surface-primary-1'
      }
      variant="head-24">
      {booking.id} (Дом {booking.house_id})
    </Typography>
    <Spacer size={12} />
    <div className={styles.card__info}>
      <ListItem label="Дата заезда" value={booking.start_date!} />
      <ListItem label="Дата отъезда" value={booking.end_date!} />
      <ListItem label="Дней пребывания" value={booking.duration_days!} />
      <ListItem
        label="Общая стоимость"
        value={`${formatAmount(booking.price!, true)} ₽`}
      />
    </div>
  </Link>
);
