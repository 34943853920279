import { FC, useMemo } from 'react';
import moment from 'moment';

import { Divider, Typography } from '@house-booking/ui-kit';
import {
  RemoteOperationStatusEnum,
  RemoteOperationTypeEnum,
} from '@shared/api';

import styles from './styles.module.scss';

export interface HouseOperationItemProps {
  type: RemoteOperationTypeEnum;
  date: string;
  status: RemoteOperationStatusEnum;
}
export const HouseOperationItem: FC<HouseOperationItemProps> = ({
  date,
  status,
  type,
}) => {
  const formattedType = useMemo(() => {
    switch (type) {
      case RemoteOperationTypeEnum.SetActiveBooking: {
        return 'Старт бронирования';
      }
      case RemoteOperationTypeEnum.OpenHouse: {
        return 'Открытие дома';
      }
      case RemoteOperationTypeEnum.PauseHouse: {
        return 'Пауза';
      }
      default: {
        return type;
      }
    }
  }, [type]);

  const formattedStatus = useMemo(() => {
    switch (status) {
      case RemoteOperationStatusEnum.$0: {
        return 'Выполняется';
      }
      case RemoteOperationStatusEnum.$1: {
        return 'Успешно';
      }
      case RemoteOperationStatusEnum.$2: {
        return 'Ошибка';
      }
    }
  }, [type]);

  return (
    <div className={styles.eventItem}>
      <div>
        <Typography variant="body-16" color="on-surface-primary-1">
          {formattedType}
        </Typography>
        <Typography variant="body-14" color="on-surface-primary-2">
          {moment.utc(date).local().format('DD.MM.YYYY HH:mm')}
        </Typography>
      </div>
      <div className={styles.eventItem__right}>
        <Typography variant="body-16" color="on-surface-primary-1">
          {formattedStatus}
        </Typography>
      </div>
      <Divider className={styles.eventItem__divider} />
    </div>
  );
};
