import { useEffect } from 'react';

import { toaster } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { useModal } from '@shared/libs';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export const useCancelBooking = () => {
  const [cancelBooking, { data, error }] =
    sdk.useCancelBookingByUserBookingCancelByUserPostMutation();
  const openCancelBookingModal = useModal('cancelBooking');

  const handleCancelBooking = (id: number) => {
    openCancelBookingModal({
      onConfirm() {
        cancelBooking({
          bookingId: id,
        });
      },
    });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Бронирование успешно отменено',
      });
    }
    if (error) {
      toaster.error({
        title: isFetchBaseQueryError(error)
          ? error.data.error_details
          : 'Ошибка отмены бронирования',
      });
    }
  }, [data, error]);

  return { data, error, handleCancelBooking };
};
