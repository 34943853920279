import { FC } from 'react';

import {
  useBookingActionStatuses,
  useCancelBooking,
  useCreateOperation,
  useMakePayment,
} from '@entities/Booking/libs/hooks';
import { Button, LoaderOverlay, Tooltip } from '@house-booking/ui-kit';
import {
  BookingResponse,
  HouseResponse,
  RemoteOperationTypeEnum,
} from '@shared/api';

import styles from './styles.module.scss';

export interface BookingActionsProps {
  booking: Required<BookingResponse>;
  house: Required<HouseResponse>;
}
export const BookingActions: FC<BookingActionsProps> = ({ booking, house }) => {
  const {
    endedBooking,
    isLoading,
    isPauseHouse,
    nowBookingDates,
    paidBooking,
    startedBooking,
  } = useBookingActionStatuses(booking, house);
  const { handleCreatePayment } = useMakePayment(booking.id!);
  const { handleCancelBooking } = useCancelBooking();
  const handleOpenHouse = useCreateOperation(
    house.id,
    booking.id,
    RemoteOperationTypeEnum.OpenHouse,
  );
  const handlePauseHouse = useCreateOperation(
    house.id,
    booking.id,
    RemoteOperationTypeEnum.PauseHouse,
  );
  const handleStartBooking = useCreateOperation(
    house.id,
    booking.id,
    RemoteOperationTypeEnum.SetActiveBooking,
  );

  if (isLoading) {
    return <LoaderOverlay show />;
  }

  if (endedBooking) {
    return <></>;
  }

  if (!paidBooking) {
    return (
      <div className={styles.bookingActions}>
        <Button onClick={() => handleCreatePayment()}>Оплатить</Button>
        <Button onClick={() => handleCancelBooking(booking.id)} color="danger">
          Отменить бронирование
        </Button>
      </div>
    );
  }

  if (!nowBookingDates) {
    return (
      <div className={styles.bookingActions}>
        <Button onClick={() => handleCancelBooking(booking.id)} color="danger">
          Отменить бронирование
        </Button>
      </div>
    );
  }

  if (!startedBooking) {
    return (
      <div className={styles.bookingActions}>
        <Button onClick={() => handleStartBooking()}>Начать аренду</Button>
        <Button onClick={() => handleCancelBooking(booking.id)} color="danger">
          Отменить бронирование
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.bookingActions}>
      <Button onClick={() => handleOpenHouse()}>Открыть дверь</Button>
      <Tooltip
        disabled={!isPauseHouse}
        label="Дом в данный момент уже на паузе"
        placement="top">
        <div>
          <Button onClick={() => handlePauseHouse()} disabled={isPauseHouse}>
            Пауза
          </Button>
        </div>
      </Tooltip>
      <Button onClick={() => handleCancelBooking(booking.id)} color="danger">
        Завершить аренду
      </Button>
    </div>
  );
};
