import {
  BookingResponse,
  BookingStatusEnum,
  HouseResponse,
  HouseStatusEnum,
  RemoteOperationStatusEnum,
  sdk,
} from '@shared/api';
import { isCurrentDateInRange } from '@shared/libs';

export const useBookingActionStatuses = (
  booking: Required<BookingResponse>,
  house: Required<HouseResponse>,
) => {
  const { data: listOperations, isLoading: isLoadingOperations } =
    sdk.useListRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGetQuery(
      {
        bookingId: Number(booking.id),
        houseId: Number(house.id),
      },
    );
  const nowBookingDates = isCurrentDateInRange(
    booking.start_date!,
    booking.end_date!,
  );

  const countSuccessOperations =
    listOperations?.remote_operations?.filter(
      (el) => el.status === RemoteOperationStatusEnum.$1,
    ).length || 0;

  return {
    endedBooking: [
      BookingStatusEnum.Completed,
      BookingStatusEnum.CanceledByUser,
      BookingStatusEnum.CanceledByPaymentTimeout,
      BookingStatusEnum.CanceledByPaymentDecline,
      BookingStatusEnum.Refunded,
    ].includes(booking.status),
    isLoading: isLoadingOperations,
    isPauseHouse: house.status === HouseStatusEnum.Pause,
    nowBookingDates,
    paidBooking: booking.status !== BookingStatusEnum.Pending,
    startedBooking: countSuccessOperations !== 0,
  };
};
