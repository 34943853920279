import memoize from 'memoize-one';

import {
  CalendarDate,
  CalendarDay,
  CalendarDayState,
  CalendarDisabled,
  CalendarOptions,
  CalendarReserved,
  CalendarSelected,
  CommonProps,
} from './types';

import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isBetween,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from './utils/date.utils';

export function cn(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export function getAttributes(attributes: Record<string, boolean>) {
  return Object.entries(attributes).reduce(
    (a, [k, v]) => (v ? { ...a, [k]: true } : a),
    {},
  );
}

export const getSelectedDates = (selected: CalendarSelected[]): Date[] =>
  selected
    .map((s) => (s ? new Date(s) : null))
    .concat(Array.from({ length: 2 - selected.length }, () => null)) as Date[];

export const preProtection = (
  date: Date,
  state: CalendarDayState,
  commonProps: CommonProps,
): CalendarSelected[] | null => {
  const { isStart, protection, range, selected } = commonProps;

  if (state.isDisabled || !!protection) {
    return null;
  }

  const [startDate, endDate] = selected;

  if (range) {
    return startDate ? [startDate, date] : [date];
  }

  return isStart ? [date, endDate] : [startDate, date];
};

// ==============================
// Day helpers
// ==============================

interface CreateMonthDays {
  dateOfMonth: CalendarDate;
  options?: CalendarOptions;
}
export const createMonthDays = memoize(
  ({ dateOfMonth, options }: CreateMonthDays) => {
    const days: CalendarDay[] = [];

    const monthStart = startOfMonth(dateOfMonth);
    const monthEnd = endOfMonth(dateOfMonth);
    const weekStart = startOfWeek(monthStart, options);
    const weekEnd = endOfWeek(monthEnd, options);

    let day = weekStart;

    while (day <= weekEnd) {
      const cloneDay = day;

      days.push({ date: cloneDay, monthStartDate: monthStart });

      day = addDays(cloneDay, 1);
    }

    return days;
  },
);

type CreateScrollableDays = CreateMonthDays & {
  monthsCount: number;
};
export const createScrollableDays = memoize(
  ({
    dateOfMonth,
    monthsCount = 6,
    options,
  }: CreateScrollableDays): CalendarDay[] => {
    let days: CalendarDay[] = [];

    for (const i in Array.from({ length: monthsCount })) {
      const currentMonth = addMonths(dateOfMonth, +i);
      const monthDays = createMonthDays({
        dateOfMonth: currentMonth,
        options,
      }) as CalendarDay[];

      const rowFiller: CalendarDay[] = Array.from({ length: 7 }).map(() => ({
        date: currentMonth,
        isMonthRow: true,
        monthStartDate: currentMonth,
      }));

      days = [...days, ...rowFiller, ...monthDays];
    }

    return days;
  },
);

interface DayStateProps {
  day: CalendarDay | CalendarDay;
  selected?: CalendarSelected[];
  reserved?: CalendarReserved[];
  disabled?: CalendarDisabled;
}
export const getDayState = ({
  day,
  disabled = false,
  reserved = [],
  selected = [],
}: DayStateProps): CalendarDayState => {
  const { date, monthStartDate } = day;

  const today = new Date();

  const state: CalendarDayState = {};

  state.isDisabled =
    typeof disabled === 'function' ? disabled(date, state) : disabled;
  state.isSameYear = date.getFullYear() === today.getFullYear();
  state.isSameMonth = isSameMonth(date, monthStartDate);
  state.isStartMonth = isSameDay(date, startOfMonth(date));
  state.isEndMonth = isSameDay(date, endOfMonth(date));
  state.isToday = isSameDay(date, today);
  state.isPast = date.getTime() + 86_400_000 - today.getTime() < 0;
  state.isSelectedStart = selected[0] ? isSameDay(date, selected[0]) : false;
  state.isSelectedEnd = selected[1] ? isSameDay(date, selected[1]) : false;
  state.isSelected = !!(
    selected[0] &&
    selected[1] &&
    isBetween(date, selected[0], selected[1], '{}')
  );
  state.isReserved = !!reserved.some(
    (r) =>
      isBetween(endOfDay(date), r.startDate, r.endDate, '{}') &&
      isBetween(startOfDay(date), r.startDate, r.endDate, '{}'),
  );
  state.isReservedStart = !!reserved.some((r) => isSameDay(r.startDate, date));
  state.isReservedEnd = !!reserved.some((r) => isSameDay(r.endDate, date));

  return state;
};

export function getDayAttributes(
  state: CalendarDayState,
  options?: CalendarOptions,
) {
  if (!options || !options.useAttributes) {
    return {};
  }

  const attributes: Record<string, boolean> = {};

  if (state.isSelected || state.isSelectedStart || state.isSelectedEnd) {
    attributes['data-selected'] = true;
  }
  if (state.isSelectedStart) {
    attributes['data-selected-start'] = true;
  }
  if (state.isSelectedEnd) {
    attributes['data-selected-end'] = true;
  }
  if (state.isReserved) {
    attributes['data-reserved'] = true;
  }
  if (state.isReservedStart) {
    attributes['data-reserved-start'] = true;
  }
  if (state.isReservedEnd) {
    attributes['data-reserved-end'] = true;
  }
  if (state.isPast) {
    attributes['data-past'] = true;
  }
  if (state.isToday) {
    attributes['data-today'] = true;
  }

  return attributes;
}

// ==============================
// Boolean
// ==============================

export const isClickable = ({
  isDisabled,
  isPast,
  isReserved,
}: CalendarDayState): boolean => {
  if (isDisabled) {
    return false;
  }

  if (isPast) {
    return false;
  }

  return !isReserved;
};

export function isNumeric(value: any) {
  return typeof value === 'number';

  if (typeof value !== 'string') {
    return false;
  } // we only process strings!

  return (
    !Number.isNaN(+value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(Number.parseFloat(value))
  ); // ...and ensure strings of whitespace fail
}
