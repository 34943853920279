import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-justanother-router';
import { useDispatch } from 'react-redux';

import { RouterName } from '@app/router';
import { authErrors } from '@entities/Auth';
import { Maybe } from '@house-booking/ui-kit';
import { BodyLoginAuthSignInPost, JwtTokenResponse, sdk } from '@shared/api';
import { CustomErrors } from '@shared/api/types';
import {
  ACCESS_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
} from '@shared/const/auth.const';
import { removeCookie, setCookie } from '@shared/libs';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

interface UseAuth {
  loading: boolean;
  error: Partial<Record<keyof BodyLoginAuthSignInPost, string>>;
  setError: (e: UseAuth['error']) => void;
  setData: (e: BodyLoginAuthSignInPost) => void;
  successHandler: (e: JwtTokenResponse) => void;
}
export const useAuth = (): UseAuth => {
  const dispatch = useDispatch();
  const [authData, setAuthData] =
    useState<Maybe<BodyLoginAuthSignInPost>>(null);
  const [authError, setAuthError] = useState<UseAuth['error']>({});
  const [jwtData, setJwtData] = useState<Maybe<JwtTokenResponse>>(null);
  const { navigate } = useNavigate();
  const [
    signInMutate,
    { data: signInData, error: signInError, isLoading: authLoading },
  ] = sdk.useLoginMutation();
  const [getMe, { data: meData, isError: isErrorMe }] =
    sdk.useLazyMeUserMeGetQuery();

  const successHandlerSignIn: UseAuth['successHandler'] = (signInData) => {
    setJwtData(signInData);
    setCookie(ACCESS_TOKEN_COOKIE_KEY, signInData.access_token, {
      'max-age': 3600,
      secure: true,
    });
    setCookie(REFRESH_TOKEN_COOKIE_KEY, signInData.refresh_token, {
      'max-age': 3600,
      secure: true,
    });
    getMe();
  };
  const errorHandlerSignIn = (signInError: CustomErrors['data']) => {
    setAuthError(authErrors[signInError.error_code]);
  };

  useEffect(() => {
    if (signInError && isFetchBaseQueryError(signInError)) {
      errorHandlerSignIn(signInError.data);
    }
    if (signInData) {
      successHandlerSignIn(signInData);
    }
  }, [signInData, signInError]);

  useEffect(() => {
    if (isErrorMe) {
      removeCookie(ACCESS_TOKEN_COOKIE_KEY);
      removeCookie(REFRESH_TOKEN_COOKIE_KEY);
    }
    if (meData && jwtData) {
      navigate(RouterName.Filter);
    }
  }, [isErrorMe, meData, jwtData]);

  const handleAuth = useCallback(async (authData: BodyLoginAuthSignInPost) => {
    dispatch(sdk.util.resetApiState());
    signInMutate({ bodyLoginAuthSignInPost: authData });
  }, []);

  useEffect(() => {
    if (authData) {
      handleAuth(authData);
    }
  }, [authData]);

  return {
    error: authError,
    loading: authLoading,
    setData: setAuthData,
    setError: setAuthError,
    successHandler: successHandlerSignIn,
  };
};
