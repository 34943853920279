import { FC, PropsWithChildren } from 'react';
import { Redirect } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { useIsAuthenticated } from '@shared/libs';
import { BaseLayout } from '@widgets/Layout';

export const AuthLayout: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const isAuth = useIsAuthenticated();

  if (isAuth()) {
    return <Redirect to={RouterName.Filter} />;
  }

  return <BaseLayout>{children}</BaseLayout>;
};
