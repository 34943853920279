import moment from 'moment';

export function isCurrentDateInRange(
  start_date: string,
  end_date: string,
): boolean {
  const currentDate = moment();

  // Устанавливаем время начала дня с 14:00
  const startDate = moment(start_date).local().hour(14).minute(0).second(0);

  // Устанавливаем время конца дня до 12:00 следующего дня
  const endDate = moment(end_date).local().hour(12).minute(0).second(0);

  // Проверяем, находится ли текущее время в указанном диапазоне
  return currentDate.isBetween(startDate, endDate, undefined, '[]');
}
