import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { sdk } from '@shared/api';
import {
  ACCESS_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
} from '@shared/const';
import { removeCookie } from '@shared/libs';

export const LogoutView = () => {
  const [logout] = sdk.useLogoutAuthLogoutPostMutation();
  const { navigate } = useNavigate();

  useEffect(() => {
    removeCookie(REFRESH_TOKEN_COOKIE_KEY);
    removeCookie(ACCESS_TOKEN_COOKIE_KEY);
    logout();
    navigate(RouterName.Login);
  }, []);

  return <></>;
};
