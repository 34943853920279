import { FC } from 'react';
import { Link } from 'react-justanother-router';

import logoPng from '@/app/assets/images/logo.png';
import { Container, LoginIcon } from '@/shared';
import { RouterName } from '@app/router';
import { Button } from '@house-booking/ui-kit';
import { useIsAuthenticated } from '@shared/libs';

import { ProfileDropdown } from '../ProfileDropdown/ProfileDropdown';

import styles from './styles.module.scss';

export interface HeaderProps {
  homeRoute?: RouterName;
  navigationSlot?: JSX.Element;
}
export const Header: FC<HeaderProps> = ({
  homeRoute = RouterName.Filter,
  navigationSlot,
}): JSX.Element => {
  const isAuth = useIsAuthenticated();

  return (
    <header className={styles.header}>
      <div className={styles.header__main_header}>
        <Container>
          <div className={styles.header__content}>
            <div className={styles.header__main}>
              <Link className={styles.header__logoLink} to={homeRoute}>
                <img src={logoPng} alt="logo" className={styles.header__logo} />
              </Link>
              {navigationSlot}
            </div>
            <div className={styles.header__control}>
              {isAuth() ? (
                <ProfileDropdown />
              ) : (
                <Button
                  prependIcon={LoginIcon}
                  icon
                  to={RouterName.Login}
                  variant="text"
                  size="large"
                />
              )}
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};
